.App {
  text-align: center;
}

@media (max-width: 767px) {
  .App {
    padding-top: 53px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bordered-table-sticky {
  th::after,
  th::before,
  td.col::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  th.col::before,
  td.col::before {
    bottom: 0;
    border-left: 1px solid theme('colors.brand-soft-borders');
    border-top: 1px solid theme('colors.brand-soft-borders');
    border-bottom: 1px solid theme('colors.brand-soft-borders');
    left: -0.5px;
    top: 1px;
  }

  td.col::before {
    left: 1px;
    z-index: -1;
  }

  td.col::before {
    border-right: 1px solid theme('colors.brand-soft-borders');
    width: calc(100% - 1px);
    height: calc(100% - 1px);
  }

  th.col:last-of-type:before {
    border-right: 1px solid theme('colors.brand-soft-borders');
    left: 0px;
  }

  th.row::after {
    bottom: 0;
    border-right: 1px solid theme('colors.brand-soft-borders');
    border-left: 1px solid theme('colors.brand-soft-borders');
    border-top: 1px solid theme('colors.brand-soft-borders');
    top: -0.5px;
    left: 1px;
  }

  th.row:last-of-type:before {
    border-bottom: 1px solid theme('colors.brand-soft-borders');
    top: 0.5px;
  }
} 

div.react-datepicker-wrapper {
  width: unset;
}

@media screen {
  .onlyPrint {
      display: none !important;
  }
}

@media print {
  .noPrint {
      display: none !important;
  }
}