.label-grid {
    display: grid;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    padding: 15mm 15mm 15mm 15mm;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 15mm;
    page-break-after: always;
    //background-color: red;

    > .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}