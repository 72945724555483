@tailwind base;
@tailwind components;
@tailwind utilities;

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-Bold.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-BoldItalic.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-BoldItalic.woff') format('woff');
//     font-weight: bold;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-DemiBoldItalic.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-DemiBoldItalic.woff') format('woff');
//     font-weight: 600;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-HeavyItalic.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-HeavyItalic.woff') format('woff');
//     font-weight: 900;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-Heavy.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-Heavy.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-DemiBold.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-DemiBold.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-Italic.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-Italic.woff') format('woff');
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-Regular.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-Medium.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-Medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-MediumItalic.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-MediumItalic.woff') format('woff');
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Avenir Next';
//     src: url('./assets/fonts/AvenirNext-UltraLightItalic.woff2') format('woff2'),
//         url('./assets/fonts/AvenirNext-UltraLightItalic.woff') format('woff');
//     font-weight: 200;
//     font-style: italic;
//     font-display: swap;
// }

// html * {
//     font-family: 'Avenir Next';
// }

body {
    color: theme('colors.brand-grey');
}

.content {
    color: theme('colors.brand-grey');
}

h1 {
    font-size: 2em;
    color: theme('colors.brand-grey');
}

input, select, textarea, .input-style {
    border-bottom: 1px solid theme('colors.brand-grey');
    color: theme('colors.brand-grey');
    padding: 2px 2px 2px 2px;
    font-size: 14px;
}

.input-style {
    border-bottom: none;
}

.arrows {
    input[type=number]::-webkit-inner-spin-button {
        opacity: 1
    }
}

textarea {
    height: auto;
}

label {
    color: #969696;
    font-size: 12px;
}

::placeholder {
    color: theme('colors.brand-grey');
}

.btn {
    cursor: pointer;
    border: 1px solid theme('colors.brand-grey');
    border-radius: 100px;
    padding: 7px 20px 7px 20px;
    color: theme('colors.brand-grey');
    min-width: 150px;
    font-size: 14px;
    text-align: center;
    justify-content: center;
}

.btn-bar {
    border-width: 0 1px 0 0;
    border-radius: 0;
    padding: 10px 30px 10px 30px;
}

.btn-bar:last-of-type {
    border-width: 0;
}

.btn.danger {
    border: 1px solid rgb(220, 38, 38);
    color: rgb(220, 38, 38);
}

.btn.btn-sm {
    min-width: 0px;
    font-size: 12px;
    padding: 4px 20px 4px 20px;
}

.btn:hover {
    color: white;
    background-color: theme('colors.brand-grey');
}

.btn.danger:hover {
    background-color: rgb(220, 38, 38);
}

/* Double Scroll Lib Vertical Fix */
.max-h-stock-grid > div > div {
    overflow: auto !important;
    max-height: calc(100vh - 250px);
}

/* Hide Arrows On Number Input */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button,
.no-arrows {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
}